var topIconsMock = {
    result_count: 26,
    viewMoreUrl: 'https://www.freepik.com/search?format=search&iconType=standard&last_filter=iconType&last_value=standard&query=&type=icon',
    images: [
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/17844/17844586.png",
        referral_destination: "https://www.freepik.com/icon/advertising_17844586"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/2504/2504957.png",
        referral_destination: "https://www.freepik.com/icon/whatsapp_2504957"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/1828/1828640.png",
        referral_destination: "https://www.freepik.com/icon/check_1828640"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/833/833472.png",
        referral_destination: "https://www.freepik.com/icon/heart_833472"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/3135/3135715.png",
        referral_destination: "https://www.freepik.com/icon/profile_3135715"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/3652/3652191.png",
        referral_destination: "https://www.freepik.com/icon/schedule_3652191"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/3046/3046121.png",
        referral_destination: "https://www.freepik.com/icon/tik-tok_3046121"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/854/854878.png",
        referral_destination: "https://www.freepik.com/icon/map_854878"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/1256/1256650.png",
        referral_destination: "https://www.freepik.com/icon/teamwork_1256650"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/1055/1055666.png",
        referral_destination: "https://www.freepik.com/icon/ux_1055666"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/5968/5968428.png",
        referral_destination: "https://www.freepik.com/icon/after-effects_5968428"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/595/595067.png",
        referral_destination: "https://www.freepik.com/icon/warning_595067"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/724/724927.png",
        referral_destination: "https://www.freepik.com/icon/warning_595067"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/3281/3281306.png",
        referral_destination: "https://www.freepik.com/icon/growth_3281306"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/6711/6711656.png",
        referral_destination: "https://www.freepik.com/icon/cross_6711656"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/185/185547.png",
        referral_destination: "https://www.freepik.com/icon/toilet_185547"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/616/616489.png",
        referral_destination: "https://www.freepik.com/icon/star_616489"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/2504/2504739.png",
        referral_destination: "https://www.freepik.com/icon/google_2504739"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/411/411776.png",
        referral_destination: "https://www.freepik.com/icon/free-delivery_411776"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/2953/2953423.png",
        referral_destination: "https://www.freepik.com/icon/money-bag_2953423"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/741/741407.png",
        referral_destination: "https://www.freepik.com/icon/car_741407"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/596/596092.png",
        referral_destination: "https://www.freepik.com/icon/megaphone_596092"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/2779/2779775.png",
        referral_destination: "https://www.freepik.com/icon/graphic-designer_2779775"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/4859/4859784.png",
        referral_destination: "https://www.freepik.com/icon/teamwork_4859784"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/1055/1055644.png",
        referral_destination: "https://www.freepik.com/icon/report_1055644"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/1067/1067346.png",
        referral_destination: "https://www.freepik.com/icon/like_1067346"
      },
    ],
  };

var peopleStickersMock = {
    result_count: 26,
    viewMoreUrl: 'https://www.freepik.com/search?format=search&iconType=sticker&last_filter=query&last_value=&query=&type=icon',
    images: [
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/8356/8356689.png",
        referral_destination: "https://www.freepik.com/sticker/family_8356689"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/7138/7138235.png",
        referral_destination: "https://www.freepik.com/sticker/family_7138235"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/4593/4593624.png",
        referral_destination: "https://www.freepik.com/sticker/business_4593624"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/11199/11199563.png",
        referral_destination: "https://www.freepik.com/sticker/team-spirit_11199563"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/11010/11010901.png",
        referral_destination: "https://www.freepik.com/sticker/team-spirit_11010901"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/11199/11199618.png",
        referral_destination: "https://www.freepik.com/sticker/team-work_11199618"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/7648/7648276.png",
        referral_destination: "https://www.freepik.com/sticker/multiple-user_7648276"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/11199/11199552.png",
        referral_destination: "https://www.freepik.com/sticker/team-spirit_11199552"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/6066/6066470.png",
        referral_destination: "https://www.freepik.com/sticker/angry_6066470"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/10050/10050309.png",
        referral_destination: "https://www.freepik.com/sticker/meetup_10050309"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/10290/10290011.png",
        referral_destination: "https://www.freepik.com/sticker/nursing-home_10290011"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/8858/8858761.png",
        referral_destination: "https://www.freepik.com/sticker/mental-health_8858761"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/7138/7138252.png",
        referral_destination: "https://www.freepik.com/sticker/family_7138252"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/7409/7409792.png",
        referral_destination: "https://www.freepik.com/sticker/hug_7409792"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/4392/4392568.png",
        referral_destination: "https://www.freepik.com/sticker/cooking_4392568"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/6066/6066595.png",
        referral_destination: "https://www.freepik.com/sticker/speech_6066595"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/10363/10363175.png",
        referral_destination: "https://www.freepik.com/sticker/arabian_10363175"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/4645/4645774.png",
        referral_destination: "https://www.freepik.com/sticker/biking_4645774"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/10252/10252521.png",
        referral_destination: "https://www.freepik.com/sticker/heart_10252521"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/9599/9599128.png",
        referral_destination: "https://www.freepik.com/sticker/happy_9599128"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/4593/4593615.png",
        referral_destination: "https://www.freepik.com/sticker/bar-chart_4593615"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/7138/7138254.png",
        referral_destination: "https://www.freepik.com/sticker/family_7138254"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/5050/5050416.png",
        referral_destination: "https://www.freepik.com/sticker/rider_5050416"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/10294/10294379.png",
        referral_destination: "https://www.freepik.com/sticker/transexual_10294379"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/10341/10341754.png",
        referral_destination: "https://www.freepik.com/sticker/nurses-day_10341754"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/7647/7647916.png",
        referral_destination: "https://www.freepik.com/sticker/stroke_7647916"
      },
    ],
  };

var natureIconsMock = {
    result_count: 26,
    viewMoreUrl: 'https://www.freepik.com/icons/nature',
    images: [
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/2795/2795602.png",
        referral_destination: "https://www.freepik.com/icon/landscape_2795602"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/188/188333.png",
        referral_destination: "https://www.freepik.com/icon/leaf_188333"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/3175/3175209.png",
        referral_destination: "https://www.freepik.com/icon/savannah_3175209"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/7057/7057859.png",
        referral_destination: "https://www.freepik.com/icon/national-park_7057859"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/4396/4396420.png",
        referral_destination: "https://www.freepik.com/icon/nature_4396420"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/4357/4357551.png",
        referral_destination: "https://www.freepik.com/icon/extreme-weather_4357551"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/7299/7299508.png",
        referral_destination: "https://www.freepik.com/icon/gaia_7299508"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/4337/4337703.png",
        referral_destination: "https://www.freepik.com/icon/bio_4337703"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/2084/2084114.png",
        referral_destination: "https://www.freepik.com/icon/park_2084114"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/346/346246.png",
        referral_destination: "https://www.freepik.com/icon/sprout_346246"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/1329/1329083.png",
        referral_destination: "https://www.freepik.com/icon/mortar_1329083"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/4200/4200115.png",
        referral_destination: "https://www.freepik.com/icon/mushroom_4200115"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/3095/3095127.png",
        referral_destination: "https://www.freepik.com/icon/fir_3095127"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/2169/2169456.png",
        referral_destination: "https://www.freepik.com/icon/park_2169456"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/8235/8235449.png",
        referral_destination: "https://www.freepik.com/icon/natural-gas_8235449"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/1147/1147560.png",
        referral_destination: "https://www.freepik.com/icon/leaves_1147560"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/2321/2321775.png",
        referral_destination: "https://www.freepik.com/icon/wildfire_2321775"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/1788/1788801.png",
        referral_destination: "https://www.freepik.com/icon/river_1788801"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/2795/2795599.png",
        referral_destination: "https://www.freepik.com/icon/landscape_2795599"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/6008/6008470.png",
        referral_destination: "https://www.freepik.com/icon/natural-gas_6008470"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/4359/4359502.png",
        referral_destination: "https://www.freepik.com/icon/smart-farm_4359502"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/9257/9257325.png",
        referral_destination: "https://www.freepik.com/icon/volcano-eruption_9257325"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/4379/4379640.png",
        referral_destination: "https://www.freepik.com/icon/natural_4379640"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/3436/3436066.png",
        referral_destination: "https://www.freepik.com/icon/landslide_3436066"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/16821/16821939.png",
        referral_destination: "https://www.freepik.com/icon/biodiversity_16821939"
      },
      {
        display_uri: "https://cdn-icons-png.freepik.com/128/8228/8228509.png",
        referral_destination: "https://www.freepik.com/icon/mountain-range_8228509"
      },
    ],
  };